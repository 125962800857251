.textTitle {
  margin-bottom: 3px;
  text-align: center;
  font-weight: 600;
}

.textValue {
  margin-bottom: 32px;
  text-align: center;
}

.trashCan {
  margin-top: 8px;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;

  svg {
    width: 120px;
    height: 120px;
  }
}
