.tex-transform-lowercase {
    text-transform: lowercase;
}

.tex-transform-uper {
    text-transform: uppercase;
}

.indented-paragraph-2 {
    text-indent: 2em;
}

.indented-paragraph-4 {
    text-indent: 4em;
}

.rt {
    color: red;
}

.normal-text {
    color: rgba(0, 0, 0, 0.88) !important;
    font-size: 14px !important;
    font-weight: 400;
}
@-webkit-keyframes my {
    0% { color: #f8360a; } 
    50% { color: transparent;  } 
    100% { color: #f8360a;  } 
}
@-moz-keyframes my { 
    0% { color: #f8360a;  } 
    50% { color: transparent;  }
    100% { color: #f8360a;  } 
}
@-o-keyframes my { 
    0% { color: #f8360a; } 
    50% { color: transparent; } 
    100% { color: #f8360a;  } 
}
@keyframes my { 
    0% { color: #f8360a;  } 
    50% { color: transparent;  }
    100% { color: #f8360a;  } 
} 
.text-flicker {
        font-size:18px;
        font-weight:bold;
    -webkit-animation: my 2000ms infinite;
    -moz-animation: my 2000ms infinite; 
    -o-animation: my 2000ms infinite; 
    animation: my 2000ms infinite;
}