.bg-white {
    background-color: #fff;
}

.cancel-row {
    background: #f0eded;
  }
tr.cancel-row {
background-color: #f0eded;
.ant-table-cell-fix-right {
    background-color: #f0eded;
}
}
.ant-table-wrapper {
    .wrong-line {
        background: #ffe0e0;
        .ant-table-cell-fix-right {
            background: #ffe0e0;
        }
    }
}