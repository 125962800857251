.ant-modal .ant-modal-header {
  background-color: var(--color-primary);
  color: #fff;
  position: relative;
  padding: 8px 0;
}
.ant-modal-close {
  top: 12px !important;
}
.ant-modal-close-icon {
  svg path {
    fill: #fff;
  }
}
.ant-modal-header .ant-modal-title {
  font-weight: 600;
  font-size: 20px;
  background-color: var(--color-primary);
  color: #fff;
  text-align: center;
}
.ant-modal-footer {
  position: relative;
  padding: 0 0 16px !important;
  margin: 12px 16px 0px 16px !important;
}